import { Injectable } from "@angular/core";
import { ApiService } from "./Api.service";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  productsUrl = "products";
  productsPublicUrl = "public/products";

  constructor(private apiService: ApiService) {}

  async getListWithFilters(activeOption = "", page = 1, isHomePage=false) {
    console.log('isHomePage')
    try {
      if (isHomePage) {
        return await this.apiService.get(
          "products_home?page=1".concat(activeOption)
        );
      } else {
        return await this.apiService.get(
          this.productsUrl.concat("?page=" + page).concat(activeOption)
        );
      }
    } catch (error) {
      return error;
    }
  }
  async getProductById(programId,getPublicUrl=false) {
    try {
      if (getPublicUrl){
        return await this.apiService.get(
          this.productsPublicUrl.concat("/").concat(programId)
        );
      }else{
        return await this.apiService.get(
          this.productsUrl.concat("/").concat(programId)
        );
      }
      
    } catch (error) {
      return error;
    }
  }
}
