export class ScrollableListPage {
  currentPage = 1;
  isLastPage = false;

  nextPage() {
    this.currentPage = this.currentPage + 1;
  }

  onScroll() {
    if (!this.isLastPage) {
      this.nextPage();
    }
  }
}
