import { Injectable } from "@angular/core";
import { ApiService } from "./Api.service";
import {AuthService} from 'src/app/Services/Auth/auth.service';

@Injectable({
  providedIn: "root",
})
export class ProgramsService {
  programsUrl = "programs";
  programsPublicUrl  = "public/programs";
  programsListUrl = "home/list/courses";
  programs: any;

  constructor(
              private apiService: ApiService,
              public authService: AuthService
              ) {
                let currentUser = this.authService.user;
                if (currentUser) {
                  this.programsUrl = 'programs';
                } else {
                  this.programsUrl = 'public/programs';

                }

              }

  async getPrograms() {
    try {
      const programs = await this.apiService.get(this.programsUrl);
      this.programs = programs;
      return programs;
    } catch (error) {
      return error;
    }
  }

  async getListWithFilters(filters = "", page = 1) {
    try {
      return await this.apiService.get(
        this.programsUrl.concat("?page=" + page).concat(filters)
      );
    } catch (error) {
      return error;
    }
  }
  
  async getProgramById(programId) {
    try {
      return await this.apiService.get(
        this.programsUrl.concat("/").concat(programId)
      );
    } catch (error) {
      return error;
    }
  }
}
