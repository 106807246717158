import { Injectable } from '@angular/core';
import { ApiService } from './Api.service';
import {HttpClient, HttpHeaders} from "@angular/common/http";

import download from 'downloadjs'

@Injectable({
  providedIn: 'root'
})
export class CertificatesService {
  private statesUrl = 'states';

  constructor(private apiService: ApiService,private http: HttpClient) { }

  download(certificate) {
    let link = certificate.link;
    certificate.downloading = true;
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    this.http.get(link, {headers: headers, responseType: 'blob'})
      .subscribe((data) => {
          console.log(data);
          // var blob = new Blob([data], {type: 'application/pdf'});
          // console.log(blob);
          download(data, "certificado.pdf");
          certificate.downloading = false;
        },
        err => {
          console.log(err);
          certificate.downloading = false;
        }
      );

  }
}
